import { delay } from "../helper/async";

export type BrowserNotification = NotificationInput & {
    id: string,
    ttlOpen?: number
}

export type NotificationInput = {
    type: 'error' | 'info' | 'success' | 'warning',
    message: string,
    /** Time until the notification is removed in ms */
    time2live?: number,
    closable?: boolean,
    title?: string,
    progress?: number
}

export const useNotifications = defineStore('notifications', () => {
    const notificationStore = ref<BrowserNotification[]>([]);

    const addNotification = (notificationInput: NotificationInput) => {
        let notification = { ...notificationInput } as BrowserNotification
        if (notification.time2live == undefined) notification.time2live = notification.progress !== undefined ? 1000000 : 5000;
        notification.id = crypto.randomUUID();
        if (notification.time2live > 0) {
            notification.ttlOpen = notification.time2live;
            delay(500).then(async () => {
                while (notification?.ttlOpen && notification.ttlOpen > 0) {
                    notification.ttlOpen -= 500;
                    notificationStore.value = [...notificationStore.value]; // trigger watcher to re-render
                    await delay(500)
                }
                if (notification.id) removeNotification(notification.id);
            });

        }
        notificationStore.value.unshift(notification);
        return notification.id;
    }

    const updateNotification = async (id: string, update: Partial<NotificationInput>) => {
        let index = notificationStore.value.findIndex(i => i.id === id);
        if (index > -1) {
            notificationStore.value[index] = { ...notificationStore.value[index], ...update };
            notificationStore.value = [...notificationStore.value]; // trigger watcher to re-render

            if (update.progress && update.progress >= 100) {
                await delay(1000);
                removeNotification(id);
            }

        }
    }

    const removeNotification = (id: string | { id: string }) => {
        if (typeof id === 'string') notificationStore.value = notificationStore.value.filter(i => i.id !== id);
        else notificationStore.value = notificationStore.value.filter(i => i.id !== id.id);
    }

    const notifications = computed((): BrowserNotification[] => {
        return notificationStore.value;
    })

    return {
        notifications,
        add: addNotification,
        addNotification,
        removeNotification,
        updateNotification,
        update: updateNotification,
    }

});